/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Headroom from 'react-headroom';
import './Header.scss';
import { Link } from 'gatsby';
import Button from '../Button/Button';
import ContactPopup from '../ContactPopup/ContactPopup';
import { useDownloadApp } from '../ContactPopup/contactContext';

function Header({ lightHead, fixHead }) {
  const [navOpen, setnavOpen] = useState(false);

  const navOpenTrigger = useCallback(() => {
    setnavOpen((state) => !state);
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', function (event) {
      if (event.key === 'Escape') {
        setnavOpen(false);
      }
    });
  }, []);

  useEffect(() => {
    if (navOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [navOpen]);

  const [downloadAppPopup, setdownloadAppPopup] = useState(false);

  const downloadAppPopupTrigger = useCallback(() => {
    setdownloadAppPopup((state) => !state);
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', function (event) {
      if (event.key === 'Escape') {
        setdownloadAppPopup(false);
      }
    });
  }, []);

  const { popupAppClick } = useDownloadApp();

  // const headerRef = useRef();

  // useEffect(() => {
  //   window.onscroll = function () {
  //     myFunction();
  //   };

  //   const sticky = headerRef.current.offsetTop;

  //   function myFunction() {
  //     if (window.pageYOffset > sticky) {
  //       headerRef.current.classList.add('sticky');
  //     } else {
  //       headerRef.current.classList.remove('sticky');
  //     }
  //   }
  // }, []);

  const renderLogo = () => {
    if (lightHead) {
      if (navOpen) {
        return <img src="/img/aeldra-logo-black.png" alt="aeldra" />;
      }

      return <img src="/img/aeldra-logo-white.png" alt="aeldra" />;
    }
    return <img src="/img/aeldra-logo-black.png" alt="aeldra" />;
  };

  return (
    <>
      <Headroom
        disableInlineStyles
        className={`headroom 
        ${lightHead ? 'light-header' : ''} 
        ${navOpen ? 'nav-opened' : ''} 
        ${fixHead ? 'header-fixed' : ''}`}>
        <header className={`header-main ${navOpen ? 'nav-open' : ''}`}>
          <div className="container">
            <div className="flex align-center">
              <div className=" col-3">
                <Link className="header-logo" to="/">
                  {renderLogo()}
                </Link>
              </div>
              <div className=" col-9">
                <div className="header-links flex align-center">
                  <div className="link-skew-block">
                    <Link
                      className="link-skew-text "
                      data-text="Add Funds"
                      to="/add-funds">
                      Add Funds
                    </Link>
                  </div>
                  <div className="link-skew-block">
                    <Link
                      className="link-skew-text "
                      data-text="FAQs"
                      to="/faq">
                      FAQs
                    </Link>
                  </div>
                  <Button onClick={downloadAppPopupTrigger} href="#!" secondary>
                    Download App
                  </Button>
                  <button
                    onClick={navOpenTrigger}
                    type="button"
                    className="button-unstyled nav-hamburger">
                    <span />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>
      </Headroom>

      <div className={`menu-main ${navOpen ? 'show-nav' : ''}`}>
        <div className="menu-content">
          <div className="flex flex-wrap">
            <div className="col-md col-6">
              <div className="nav-left-area">
                <ul className="nav-links">
                  <li className="link-skew-block">
                    <Link
                      to="/about"
                      className="link-skew-text "
                      data-text="About us">
                      About us
                    </Link>
                  </li>
                  <li className="link-skew-block">
                    <Link
                      to="/product"
                      className="link-skew-text "
                      data-text="Our products">
                      Our products
                    </Link>
                  </li>
                  <li className="link-skew-block">
                    <Link
                      to="/add-funds/"
                      className="link-skew-text "
                      data-text="Add funds">
                      Add funds
                    </Link>
                  </li>
                  <li className="link-skew-block">
                    <Link
                      to="/faq"
                      className="link-skew-text "
                      data-text="FAQs">
                      FAQs
                    </Link>
                  </li>
                  <li className="open-account-link link-skew-block">
                    <a
                      href="https://prod.aeldra.com/get-started"
                      className="link-skew-text "
                      data-text="Open an account">
                      Open an account{' '}
                    </a>
                    <svg width="32" height="32" fill="none" viewBox="0 0 32 32">
                      <path
                        stroke="#BEB281"
                        strokeLinecap="round"
                        strokeWidth="2.5"
                        d="M2 30 30 2M10 2h20M30 22V2"
                      />
                    </svg>
                  </li>
                </ul>

                <ul className="nav-social flex hidden-tablet">
                  <li className="link-skew-block">
                    <a
                      href="https://www.facebook.com/AeldraGlobal/"
                      className="link-skew-text "
                      data-text="FACEBOOK">
                      FACEBOOK
                    </a>
                  </li>
                  <li className="link-skew-block">
                    <a
                      href="https://www.linkedin.com/company/aeldra"
                      className="link-skew-text "
                      data-text="LINKEDIN">
                      LINKEDIN
                    </a>
                  </li>
                  <li className="link-skew-block">
                    <a
                      href="https://instagram.com/aeldra.globalbank?utm_medium=copy_link"
                      className="link-skew-text "
                      data-text="INSTAGRAM">
                      INSTAGRAM
                    </a>
                  </li>
                  <li className="link-skew-block">
                    <a
                      href="https://youtu.be/oDY6sYXhxUA"
                      className="link-skew-text "
                      data-text="YOUTUBE">
                      YOUTUBE
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md col-6">
              <div className="flex flex-wrap nav-square-block">
                <a href="tel:+918069559800" className="single-square-block">
                  <svg width="26" height="26" fill="none" viewBox="0 0 26 26">
                    <path
                      fill="#1F1F1F"
                      d="M13.001 2.166c-5.973 0-10.833 4.86-10.833 10.833 0 1.774.467 3.433 1.225 4.911L2.22 22.115c-.274.976.692 1.942 1.67 1.669l4.207-1.174c1.477.756 3.133 1.223 4.905 1.223 5.974 0 10.834-4.86 10.834-10.834 0-5.973-4.86-10.833-10.834-10.833Zm0 1.625a9.196 9.196 0 0 1 9.209 9.208A9.196 9.196 0 0 1 13 22.208a9.152 9.152 0 0 1-4.473-1.162.812.812 0 0 0-.613-.073L3.914 22.09 5.03 18.09a.813.813 0 0 0-.073-.614 9.149 9.149 0 0 1-1.165-4.477 9.196 9.196 0 0 1 9.208-9.208Zm-4.604 6.5a.814.814 0 0 0-.314 1.566c.1.04.206.06.314.059h9.208a.814.814 0 1 0 0-1.625H8.397Zm0 3.792a.814.814 0 0 0-.314 1.566c.1.04.206.06.314.059h7.042a.814.814 0 0 0 .314-1.567.814.814 0 0 0-.314-.058H8.397Z"
                    />
                  </svg>
                  <p>
                    Contact us
                    <br />
                    in India
                  </p>
                </a>
                <a href="tel:+18443333101" className="single-square-block">
                  <svg width="26" height="26" fill="none" viewBox="0 0 26 26">
                    <path
                      fill="#1F1F1F"
                      d="M10.314 2.174a3.03 3.03 0 0 0-1.08.116l-1.282.38a4.084 4.084 0 0 0-2.795 2.857c-.802 3.043.115 6.644 2.503 10.699 2.384 4.048 5.101 6.621 8.17 7.461a4.158 4.158 0 0 0 3.918-.955l.975-.905a2.954 2.954 0 0 0 .373-3.9l-1.41-1.914a3.033 3.033 0 0 0-3.31-1.095l-2.206.66c-.092.027-.313-.099-.506-.265l-.001-.001c-.344-.299-.82-.902-1.328-1.765v-.001c-.546-.927-.778-1.533-.872-1.89-.093-.359-.07-.402-.057-.523a.257.257 0 0 1 .085-.161v-.001l1.645-1.504a2.946 2.946 0 0 0 .695-3.394L12.84 3.91a3.014 3.014 0 0 0-2.526-1.736Zm-.117 1.62c.497.038.955.336 1.165.793l.992 2.163c.24.523.12 1.122-.314 1.519L10.396 9.77a1.885 1.885 0 0 0-.604 1.179c-.014.12-.045.562.1 1.116.144.554.442 1.286 1.042 2.305h.001c.56.95 1.09 1.671 1.662 2.169h.001c.186.161.966.909 2.027.599l.004-.001 2.214-.664a1.404 1.404 0 0 1 1.535.503l1.41 1.914c.398.543.328 1.282-.17 1.745l-.976.906a2.56 2.56 0 0 1-2.384.577c-2.48-.678-4.935-2.876-7.197-6.717-2.266-3.847-2.974-7.028-2.333-9.46a2.478 2.478 0 0 1 1.685-1.713l1.281-.38c.167-.05.337-.067.503-.054Z"
                    />
                  </svg>
                  <p>
                    Contact us
                    <br />
                    in the U.S.
                  </p>
                </a>
                <a
                  href="mailto:concierge@aeldra.com"
                  className="single-square-block">
                  <svg width="26" height="26" fill="none" viewBox="0 0 26 26">
                    <path
                      fill="#1F1F1F"
                      d="M5.688 4.333a3.533 3.533 0 0 0-3.521 3.521v10.292a3.533 3.533 0 0 0 3.52 3.52h14.626a3.533 3.533 0 0 0 3.52-3.52V7.854a3.533 3.533 0 0 0-3.52-3.52H5.688Zm0 1.625h14.625c1.056 0 1.896.84 1.896 1.896v.6L13 13.43 3.792 8.453v-.599c0-1.056.84-1.896 1.896-1.896ZM3.792 10.3l8.822 4.77a.812.812 0 0 0 .772 0l8.823-4.77v7.846c0 1.056-.84 1.896-1.896 1.896H5.688a1.884 1.884 0 0 1-1.896-1.896V10.3Z"
                    />
                  </svg>
                  <p>
                    Send us
                    <br />
                    an email
                  </p>
                </a>
                <a
                  href="#!"
                  onClick={popupAppClick}
                  className="single-square-block">
                  <svg width="26" height="26" fill="none" viewBox="0 0 26 26">
                    <path
                      fill="#1F1F1F"
                      d="M6.77 3.25a3.533 3.533 0 0 0-3.52 3.52v12.46a3.533 3.533 0 0 0 3.52 3.52h12.46a3.533 3.533 0 0 0 3.52-3.52V6.77a3.533 3.533 0 0 0-3.52-3.52H6.77Zm0 1.625h12.46c1.055 0 1.895.84 1.895 1.896v.812H4.875v-.812c0-1.056.84-1.896 1.896-1.896ZM4.876 9.208h16.25V19.23c0 1.056-.84 1.896-1.896 1.896H6.771a1.884 1.884 0 0 1-1.896-1.896V9.21ZM14.895 13A1.909 1.909 0 0 0 13 14.896v2.166c0 1.038.859 1.896 1.896 1.896h2.166a1.908 1.908 0 0 0 1.896-1.895v-2.167A1.908 1.908 0 0 0 17.063 13h-2.167Zm0 1.625h2.168c.16 0 .27.111.27.27v2.168c0 .16-.11.27-.27.27h-2.167a.259.259 0 0 1-.271-.27v-2.167c0-.16.111-.271.27-.271Z"
                    />
                  </svg>
                  <p>
                    Get Assistance in
                    <br />
                    Account Opening
                  </p>
                </a>

                <ul className="nav-social flex visible-tablet-flex">
                  <li className="link-skew-block">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/AeldraGlobal/"
                      className="link-skew-text "
                      data-text="FACEBOOK">
                      FACEBOOK
                    </a>
                  </li>
                  <li className="link-skew-block">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.linkedin.com/company/aeldra"
                      className="link-skew-text "
                      data-text="LINKEDIN">
                      LINKEDIN
                    </a>
                  </li>
                  <li className="link-skew-block">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://instagram.com/aeldra.globalbank?utm_medium=copy_link"
                      className="link-skew-text "
                      data-text="INSTAGRAM">
                      INSTAGRAM
                    </a>
                  </li>
                  <li className="link-skew-block">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://youtu.be/oDY6sYXhxUA"
                      className="link-skew-text "
                      data-text="YOUTUBE">
                      YOUTUBE
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`popup-block-main ${downloadAppPopup ? 'show' : ''}`}>
        <div className="popup-block black-bg">
          <button
            onClick={downloadAppPopupTrigger}
            className="close-popup"
            type="button">
            <svg width="20" height="20" fill="none" viewBox="0 0 20 20">
              <g
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.667"
                opacity=".52">
                <path d="M15 5 5 15M5 5l10 10" />
              </g>
            </svg>
          </button>

          <div className="popup-content download-app-popup">
            <h2 className="text-center" style={{ marginBottom: 30 }}>
              Download the app
            </h2>
            {/*  <p className="popup-subhead text-center">
              Enter your phone number to get the app download link via SMS
            </p>

          <div className="get-sms-link">
              <input
                className="input-phone"
                type="text"
                placeholder="Your phone number"
              />
              <Button whiteButton>Text me the app link</Button>
            </div>

            <p className="popup-subhead text-center">or download from here</p> */}

            <div className="flex justify-center flex-wrap">
              <a
                href="https://apps.apple.com/in/app/aeldra-global-bank/id1546022340"
                target="_blank"
                rel="noopener noreferrer"
                className=" button-alt button-gold">
                <svg width="14" height="16" fill="none" viewBox="0 0 14 16">
                  <path
                    fill="#1F1F1F"
                    d="M11.66 15.356c-.901.842-1.895.71-2.841.313-1.007-.405-1.928-.43-2.99 0-1.325.551-2.027.391-2.825-.313-4.502-4.468-3.837-11.273 1.28-11.53 1.24.065 2.11.662 2.84.712 1.087-.213 2.126-.823 3.29-.743 1.396.109 2.441.64 3.14 1.596-2.875 1.666-2.194 5.316.446 6.341-.528 1.337-1.206 2.658-2.342 3.635l.001-.011ZM7.023 3.779C6.89 1.793 8.56.16 10.48 0c.264 2.29-2.16 4.003-3.456 3.779Z"
                  />
                </svg>
                Download for iPhone
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.i2c.mcpcc.aeldra.global"
                target="_blank"
                rel="noopener noreferrer"
                className=" button-alt button-gold">
                <svg width="14" height="16" fill="none" viewBox="0 0 14 16">
                  <path
                    fill="#1F1F1F"
                    d="M3.067.933 10.8 5.4 8.6 7.6 1.667.733C1.8.733 2 .667 2.133.667c.334 0 .667.066.934.266ZM.4 13.6V2.4c0-.533.267-1 .667-1.333l7 7L1.133 15A1.74 1.74 0 0 1 .4 13.6Zm2.667 1.533c-.4.2-.867.267-1.267.2L8.6 8.6l2.133 2.133-7.666 4.4Zm9.666-5.6-1.4.8L9 8.067l2.333-2.334 1.334.8c.533.334.866.867.866 1.534.067.533-.266 1.133-.8 1.466Z"
                  />
                </svg>
                Download for Android
              </a>
            </div>
          </div>
        </div>
      </div>

      <ContactPopup />
    </>
  );
}

Header.propTypes = {
  lightHead: PropTypes.bool,
  fixHead: PropTypes.bool,
};

Header.defaultProps = {
  lightHead: false,
  fixHead: false,
};

export default Header;
