import React from 'react';

export const loadingText = [
  {
    id: 0,
    text: 'One-stop',
  },
  {
    id: 1,
    text: 'solution',
  },
  {
    id: 2,
    text: 'to',
  },
  {
    id: 3,
    text: 'all',
  },
  {
    id: 4,
    text: 'your',
  },
  {
    id: 5,
    text: 'global',
  },
  {
    id: 6,
    text: 'banking',
  },
  {
    id: 7,
    text: 'needs',
  },
  {
    id: 8,
    text: <>&nbsp;</>,
  },
];

export const sectionOneAsset3Texts = [
  {
    id: 0,
    content: 'Invest Globally',
  },

  {
    id: 1,
    content: 'Study Globally',
  },
  {
    id: 2,
    content: 'Travel Globally',
  },
  {
    id: 3,
    content: 'Work Globally',
  },
  {
    id: 4,
    content: 'Live Globally ',
  },
];

export const homeMarqueeContent = [
  {
    id: 0,
    heading: (
      <>
        Better than <span className="gold">carrying cash</span>
      </>
    ),
    content: 'You only miss (home) and never misplace',
  },
  {
    id: 1,
    heading: (
      <>
        Better than<span className="gold"> your ForEX </span>
      </>
    ),
    content:
      'Save up to 10% in foreign transaction fees & exchange rate mark-ups of an Indian card',
  },
];
export const homeCtaContent = [
  {
    id: 0,
    heading: <span className="gold">You do more.</span>,
    content: 'Earn up to 20x Interest vs Major U.S. Banks',
  },
  {
    id: 1,
    heading: <span className="gold"> You save more. </span>,
    content:
      'Save up to $35 per Account* Funding vs Major Incoming International Wires',
  },
  {
    id: 2,
    heading: <span className="gold"> You be more. </span>,
    content:
      'Up to $35 per Account* Funding vs Major Incoming International Wires',
  },
];

export const FaqAccordionHome = [
  {
    id: 0,
    question: 'Who is Aeldra?',
    answer: (
      <>
        <p>
          Aeldra is a digital banking* and investment platform serving students,
          professionals, and investors globally. Aeldra offers an FDIC-Insured*
          U.S. bank account* with a Mastercard^ for U.S. residents, through our
          banking* partner Blue Ridge Bank, N.A., and will soon offer a U.S.
          brokerage account*, no-FICO credit card, and no-FICO mortgage (home
          loan). Aeldra&apos;s mission is to drive global access to financial
          services in a safe and compliant way leveraging the latest
          technologies.
        </p>
        <p>
          The Company is headquartered in Silicon Valley and founded by
          CXO-level executives from Goldman Sachs, McKinsey, Cargill, Accenture,
          and Infosys. Aeldra has launched in the U.S. and India in Q1 2021,
          with a U.S. bank account*. The Company will offer a brokerage account*
          to existing Aeldra customers in Q4 2021, and will soon expand to other
          geographies.
        </p>

        <p>
          U.S. residents or Indian citizens can download the Aeldra App and
          apply for an account* digitally from anywhere in the world without the
          need to visit a branch or provide a physical document. In line with
          Aeldra&apos;s mission to make it easy for customers with a legitimate
          need for a U.S. bank account*, Indian citizens do not require a U.S.
          Social Security Number (SSN) to open an account* if they have an
          Indian passport. Account* opening is subject to Aeldra&apos;s KYC and
          AML policies in line with U.S. government regulations. Through
          proprietary algorithms, processes, and technologies Aeldra has
          digitized the KYC process. However, a small portion of applicants may
          require manual review. Applicants with U.S. banking* needs for
          employment, educational, investment, or other purposes can open a U.S.
          bank account* and fund it even from outside the U.S.
        </p>

        <p>
          The founders of Aeldra come from a conservative banking* background
          and, unlike many other FinTech&apos;s, risk management and compliance
          are in the Company&apos;s DNA. Aeldra is compliant with U.S.
          regulations and Government of India regulations. For instance,
          transfer of money from India to the Aeldra account* will need to be
          done under the RBI&apos;s Liberalized Remittance Scheme. Aeldra offers
          banking* services through a partnership with regulated financial
          institutions in the U.S. including Mastercard^ and Blue Ridge Bank,
          N.A.{' '}
        </p>
      </>
    ),
  },
  {
    id: 1,
    question: 'Who is eligible for an Aeldra account*?',
    answer: (
      <p>
        Your money with Aeldra is insured by the Federal Deposit Insurance
        Corporation, through Blue Ridge Bank N.A., Member FDIC, just as it would
        be for any large U.S. bank like JPMorgan Chase or Citibank. The FDIC -
        short for Federal Deposit Insurance Corporation - is an independent
        agency of the United States government that protects against the loss of
        insured deposits if an FDIC-Insured* bank fails. It is backed by the
        full faith and credit of the United States government. The
        FDIC-Insurance* is through Blue Ridge Bank, N.A., a nationally chartered
        U.S. bank. Since the FDIC began in 1934, no depositor has ever lost a
        penny of FDIC-Insured* funds. FDIC insurance covers funds in deposit
        accounts up to $250,000 per depositor. This means that even in the
        unlikely event that Aeldra shuts down or Blue Ridge Bank shuts down, you
        can access and withdraw your funds. For more details click here:{' '}
        <a
          href="https://www.fdic.gov/resources/deposit-insurance/faq/"
          target="_blank">
          FDIC
        </a>
        .
      </p>
    ),
  },
  {
    id: 2,
    question: 'Is my money safe with Aeldra?',
    answer: (
      <>
        <p>
          Debit cards^ issued to customers will be restricted from usage until
          the customer is physically in the U.S. Customers will have to provide
          documentation by contacting Aeldra Concierge that establishes proof of
          U.S. entry or U.S. presence to remove the usage restriction.
          Mastercard^ debit cards^ are currently available to U.S. resident
          customers with a U.S. mailing address by calling Aeldra Concierge at
          U.S. Toll-free Number: +1 844 333 3101. Cards^ ship the next business
          day by US Postal Service First Class Mail.
        </p>
        <p>
          For your convenience, you can download and refer to detailed step-wise
          screenshots of the Wise transfer process here.
        </p>

        <a href="https://uploads-ssl.webflow.com/600ce307c2c857ddb16f784a/60b0846ef7f9b684d7c89660_Wise%20Transfer%20Process.pdf">
          DOWNLOAD FILE
        </a>
      </>
    ),
  },
  {
    id: 3,
    question: 'Account* funding from a U.S. Bank account* or a mobile wallet',
    answer: (
      <p>
        <span>
          Account* to Account* Transfer (ACH, Wire)
          <br />
        </span>
        To move money to the Aeldra account*, use the online or mobile banking*
        of the bank the money is being transferred from. After logging into the
        account*, initiate the transfer to Aeldra using the Routing Number and
        bank details below.
        <br />
        <span>
          INSTRUCTIONS
          <br />
          Your Aeldra Account* Number^^
          <br />
          Bank ABA / Routing number: 053112929
          <br />
          Recipient Bank Name: Blue Ridge Bank, N.A.
          <br />
          Bank Address: 17 W Main St, Luray, VA 22835, U.S.A.
        </span>
        <br />
        ^^Your Aeldra Account* Number can be viewed in-app on the home screen by
        clicking on "View All" to the right of "Recent Transactions".
      </p>
    ),
  },
];

export const TeamMembers = [
  {
    img: '/img/anil-kumar-ns.jpg',
    name: 'Anil Kumar NS',
    designation: 'Managing Director - India',
  },
  {
    img: '/img/walid-hissen.jpg',
    name: 'Walid Hissen',
    designation: 'VP, Head of Operations and Product Management (U.S.)',
  },
  {
    img: '/img/nitin-chaturvedi.jpg',
    name: 'Nitin Chaturvedi',
    designation: 'VP, Head of Operations and Product Management (India)',
  },
  {
    img: '/img/nikhil-lasrado.jpg',
    name: 'Nikhil Lasrado',
    designation: 'VP, Partnerships and Business Development(India)',
  },
  {
    img: '/img/sumedha-uppal.jpg',
    name: 'Sumedha Uppal',
    designation: 'VP, Brand Marketing Management',
  },
  {
    img: '/img/todd-martin.jpg',
    name: 'Todd Martin',
    designation: 'VP, Partnerships and Business Development (U.S)',
  },
  {
    img: '/img/mudita-chaturvedi.jpg',
    name: 'Mudita Chaturvedi',
    designation: 'Director Communications and Corporate Affairs (India)',
  },
  {
    img: '/img/bhavesh-dwivedi.jpg',
    name: 'Bhavesh Dwivedi',
    designation: 'AVP, Digital Acquisitions and Marketing (India)',
  },
  {
    img: '/img/ashwin-s-menon.jpg',
    name: 'Ashwin S. Menon',
    designation: 'Design Associate',
  },
].map(({ name, img, designation }, id) => ({
  name,
  img,
  designation,
  id,
}));

export const getIcons = () =>
  [
    {
      name: 'warning',
      svg: (
        <>
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
        </>
      ),
      viewBox: '0 0 24 24',
      width: '24px',
      height: '24px',
    },

    {
      name: 'chevron',
      svg: (
        <path
          fill="none"
          fillRule="evenodd"
          stroke="#180048"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M1 1l4.5 5L10 1"
        />
      ),
      viewBox: '0 0 11 7',
      height: '7px',
      width: '11px',
    },
    {
      name: 'globe',
      svg: (
        <path
          fill="#000"
          d="M8.5.303a8.197 8.197 0 1 1 0 16.394A8.197 8.197 0 0 1 8.5.303ZM5.615 11.875h-2.96a6.76 6.76 0 0 0 4.391 3.217 13.837 13.837 0 0 1-1.431-3.216v-.001Zm5.77 0a13.84 13.84 0 0 1-1.43 3.216 6.757 6.757 0 0 0 4.392-3.215h-2.96l-.002-.001Zm-1.516 0H7.131c.318.95.775 1.89 1.37 2.82.593-.931 1.05-1.872 1.368-2.82Zm.38-5.304H6.751a10.383 10.383 0 0 0 0 3.858h3.496a10.384 10.384 0 0 0 0-3.858Zm-4.967 0H2.03A6.751 6.751 0 0 0 1.75 8.5c0 .67.098 1.318.28 1.928h3.253a11.822 11.822 0 0 1 0-3.857Zm9.688 0h-3.253c.211 1.277.211 2.58 0 3.857h3.253c.183-.61.28-1.258.28-1.928a6.74 6.74 0 0 0-.28-1.929ZM7.045 1.908l-.123.028a6.762 6.762 0 0 0-4.268 3.189h2.96c.321-1.09.798-2.162 1.431-3.217Zm1.455.397c-.57.88-1.03 1.828-1.37 2.82h2.74c-.34-.992-.8-1.94-1.37-2.82Zm1.454-.398.05.083a13.79 13.79 0 0 1 1.382 3.135h2.96a6.76 6.76 0 0 0-4.392-3.218Z"
        />
      ),
      viewBox: '0 0 17 17',
      width: '17px',
      height: '17px',
    },
    {
      name: 'funding-us',
      svg: (
        <path
          fill="#000"
          d="M6.019 1.75c-1.603 0-2.173.11-2.76.424A2.59 2.59 0 0 0 2.174 3.26c-.314.586-.424 1.156-.424 2.759v4.962c0 1.603.11 2.173.424 2.76a2.59 2.59 0 0 0 1.086 1.085c.586.314 1.156.424 2.759.424h4.962c1.603 0 2.173-.11 2.76-.424a2.59 2.59 0 0 0 1.085-1.086c.314-.586.424-1.156.424-2.759V6.019c0-1.603-.11-2.173-.424-2.76a2.59 2.59 0 0 0-1.086-1.085c-.586-.314-1.156-.424-2.759-.424H6.019Zm0-1.5h4.962c2.006 0 2.734.209 3.467.6a4.09 4.09 0 0 1 1.701 1.702c.392.733.601 1.46.601 3.467v4.962c0 2.006-.209 2.734-.6 3.467a4.09 4.09 0 0 1-1.702 1.701c-.733.392-1.46.601-3.467.601H6.019c-2.006 0-2.734-.209-3.467-.6A4.09 4.09 0 0 1 .85 14.447c-.392-.733-.601-1.46-.601-3.467V6.019c0-2.006.209-2.734.6-3.467A4.089 4.089 0 0 1 2.554.85C3.285.459 4.013.25 6.019.25Zm.231 12a.75.75 0 1 1-1.5 0v-4.5a.75.75 0 0 1 1.5 0v4.5Zm3 0a.75.75 0 1 1-1.5 0v-7.5a.75.75 0 0 1 1.5 0v7.5Zm3 0a.75.75 0 1 1-1.5 0v-3a.75.75 0 1 1 1.5 0v3Z"
        />
      ),
      viewBox: '0 0 17 17',
      width: '17px',
      height: '17px',
    },
    {
      name: 'database',
      svg: (
        <path
          fill="#000"
          d="M14.996 12.383c-.123 2.35-3.483 3.992-7.496 3.992-4.089 0-7.5-1.706-7.5-4.125v-7.5l.004-.133C.127 2.267 3.487.625 7.5.625 11.589.625 15 2.33 15 4.75v7.5l-.004.133Zm-1.495-5.11c-1.377.998-3.57 1.602-6.001 1.602-2.431 0-4.623-.603-6-1.6V8.5c0 1.309 2.633 2.625 6 2.625s6-1.316 6-2.625V7.274ZM7.5 2.126c-3.367 0-6 1.316-6 2.625s2.633 2.625 6 2.625 6-1.316 6-2.625-2.633-2.625-6-2.625Zm6 8.899c-1.376.997-3.569 1.601-6 1.601s-4.623-.603-6-1.6v1.225l.004.087c.114 1.28 2.704 2.538 5.996 2.538 3.367 0 6-1.316 6-2.625v-1.226Z"
        />
      ),
      viewBox: '0 0 15 17',
      width: '15px',
      height: '17px',
    },
    {
      name: 'transfers',
      svg: (
        <path
          fill="#000"
          d="m2.668 9.032 1.762 1.934.04.049c.034.045.044.056.071.107.277.495-.063 1.284-.655 1.284a.775.775 0 0 1-.543-.247L.27 8.785C.234 8.74.223 8.732.192 8.684-.11 8.228.14 7.45.693 7.354c.053-.008.067-.008.12-.01h12.294l.06.003c.053.007.066.007.119.02.519.138.774.968.364 1.418a.776.776 0 0 1-.543.247H2.668ZM16.18 5.657H3.886c-.053-.002-.066 0-.12-.01-.469-.082-.794-.721-.564-1.216a.798.798 0 0 1 .684-.461h10.439l-1.762-1.935-.04-.048c-.034-.046-.044-.057-.071-.107-.259-.463.008-1.183.534-1.275.115-.019.143-.009.18-.007.054.007.067.007.12.02.137.037.259.118.364.225l3.073 3.374c.037.044.047.053.079.1.246.373.149.968-.22 1.216a.742.742 0 0 1-.282.114c-.053.01-.067.008-.12.01Z"
        />
      ),
      viewBox: '0 0 17 13',
      width: '17px',
      height: '13px',
    },
    {
      name: 'play',
      svg: <path d="M12.787 9.281.412 18.211V.352l12.375 8.93Z" />,
      viewBox: '0 0 13 19',
      width: '13px',
      height: '19px',
    },
    {
      name: 'download',
      svg: (
        <>
          <path
            fill="#fff"
            d="M2.664 10.333a.667.667 0 0 1 1.333 0V13a.667.667 0 0 0 .667.666h6.667a.666.666 0 0 0 .666-.666v-2.667a.666.666 0 1 1 1.334 0V13a2 2 0 0 1-2 2H4.664a2 2 0 0 1-2-2v-2.667Z"
          />
          <path
            fill="#fff"
            d="M7.331 8.058V2a.667.667 0 1 1 1.333 0v6.058l1.862-1.862a.667.667 0 0 1 .943.942l-3 3a.667.667 0 0 1-.943 0l-3-3a.667.667 0 1 1 .943-.942l1.862 1.862Z"
          />
        </>
      ),
      viewBox: '0 0 16 16',
      width: '16px',
      height: '16px',
    },
  ].map(({ name, svg, viewBox, width, height }, id) => ({
    name,
    svg,
    viewBox,
    width,
    height,
    id,
  }));

export const newsContent = [
  {
    logo: '/img/mint.png',
    desc: (
      <>
        “Aeldra doesn&apos;t charge any fee for account* maintenance, mobile
        banking*, debit card, and so on.”
      </>
    ),

    url: 'https://www.livemint.com/money/personal-finance/get-a-us-bank-account-on-indian-passport-sitting-at-home-11610548667863.html',
  },

  {
    logo: '/img/money-control.png',
    desc: (
      <>
        Aeldra, the U.S.-based fintech start-up, has started offering U.S. bank
        accounts to Indians without a social security number or a U.S. address
      </>
    ),

    url: 'https://www.moneycontrol.com/news/india/indians-can-sit-at-home-and-have-a-us-bank-account-here-is-how-6344551.html',
  },

  {
    logo: '/img/business-standard.png',
    desc: (
      <>
        "Brian and Brett are among the most forward-thinking leaders in banking*
        and we are delighted to partner with them." - Sukeert Shanker, CEO
        Aeldra.
      </>
    ),

    url: 'https://www.business-standard.com/content/press-releases-ani/aeldra-and-blue-ridge-bank-launch-neobank-to-expand-u-s-financial-access-to-brics-plus-countries-including-india-121060100532_1.html',
  },
  {
    logo: '/img/bw-disrupt.svg',
    desc: (
      <>
        “The Company is backed by leading fintech investors including Greg Kidd,
        an early investor in Twitter, Square, Ripple and Coinbase.”
      </>
    ),

    url: 'http://bwdisrupt.businessworld.in/article/-Aeldra-Launches-Its-Premium-Mobile-App-/01-12-2020-348703/',
  },

  {
    logo: '/img/ani.png',
    desc: (
      <>
        "Aeldra meets a significant need in today's banking* environment." -
        Brian K Plum, President &amp; CEO Blue Ridge Bank, N.A.
      </>
    ),

    url: 'https://www.aninews.in/news/business/business/aeldra-and-blue-ridge-bank-launch-neobank-to-expand-us-financial-access-to-brics-plus-countries-including-india20210601121400/',
  },

  {
    logo: '/img/yahoo-news.png',
    desc: (
      <>
        "Sukeert previously built two leading digital banks and we're excited to
        help him try to three-peat with Aeldra." - Brett Taxin, EVP Blue Ridge.
      </>
    ),

    url: 'https://in.news.yahoo.com/aeldra-blue-ridge-bank-launch-064441114.html',
  },
  {
    logo: '/img/financial-express.png',
    desc: (
      <>
        "The benefit of having bank accounts in the U.S. for the consumers in
        India is that it will broaden their horizons."
      </>
    ),

    url: 'https://www.financialexpress.com/money/now-you-may-open-a-bank-account-in-us-to-broaden-financial-footprint/2196700/',
  },
  {
    logo: '/img/fin-extra.png',
    desc: (
      <>
        “The founding team has launched similar banking* products previously,
        including Goldman Sachs' consumer bank, Marcus."
      </>
    ),

    url: 'https://www.finextra.com/pressarticle/85501/i2c-wins-contract-with-digital-private-bank-aeldra',
  },
  {
    logo: '/img/timesnow-news.png',
    desc: (
      <>
        "Aeldra launched in 2020 with a focus on bridge banking* between India
        and the U.S."
      </>
    ),

    url: 'https://www.timesnownews.com/business-economy/personal-finance/planning-investing/article/indians-can-now-open-a-us-bank-account-while-sitting-at-home-here-is-how/707378',
  },
  {
    logo: '/img/crowdfund-insider.png',
    desc: (
      <>
        “Aeldra is on a mission to provide access to global financial
        opportunities to customers all over the world.”
      </>
    ),

    url: 'https://www.crowdfundinsider.com/2020/12/170229-u-s-fintech-aeldra-launches-global-banking-mobile-app/',
  },
  {
    logo: '/img/cxo-today.png',
    desc: (
      <>
        “Aeldra is targeting $5 billion (Rs. 37,500 crores) in deposits by
        2023.”
      </>
    ),

    url: 'https://www.cxotoday.com/press-release/aeldra-launches-its-premium-mobile-app-set-to-add-300000-accounts-with-this-new-service/',
  },
  {
    logo: '/img/pymnts.png',
    desc: (
      <>“Aeldra provides global clients with smooth access to U.S. banking*."</>
    ),

    url: 'https://www.pymnts.com/digital-first-banking/2020/aeldra-taps-i2c-to-enable-global-banking-services/',
  },
].map(({ logo, desc, url }, id) => ({
  logo,
  desc,
  url,

  id,
}));
