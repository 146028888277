import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const withDownloadApp = () => {
  const Context = React.createContext({});

  function DownloadAppProvider({ children }) {
    const [popupApp, setPopupApp] = useState(false);

    const popupAppClick = useCallback((e) => {
      if (e.type === 'click') setPopupApp((state) => !state);
    }, []);

    useEffect(() => {
      document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
          setPopupApp(false);
        }
      });
    }, []);

    useEffect(() => {
      if (popupApp) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'visible';
      }
      return () => {
        document.body.style.overflow = 'visible';
      };
    }, [popupApp]);

    return (
      <Context.Provider value={{ popupApp, popupAppClick }}>
        {children}
      </Context.Provider>
    );
  }

  DownloadAppProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };

  const useDownloadApp = () => React.useContext(Context);

  return {
    DownloadAppProvider,
    useDownloadApp,
  };
};

export const { DownloadAppProvider, useDownloadApp } = withDownloadApp();
