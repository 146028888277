/* eslint-disable jsx-a11y/media-has-caption */
import { Link } from 'gatsby';
import React from 'react';
import './Footer.scss';
import { useDownloadApp } from '../ContactPopup/contactContext';

const footerLinks = [
  {
    linkText: 'Products',
    url: '/product',
  },
  {
    linkText: 'About us',
    url: '/about',
  },
  {
    linkText: 'Add Funds',
    url: '/add-funds',
  },
  {
    linkText: 'FAQs',
    url: '/faq',
  },
].map(({ linkText, url }, id) => ({
  linkText,
  url,
  id,
}));

function play() {
  const audio = document.getElementById('audio');
  audio.play();
}

function Footer() {
  const { popupAppClick } = useDownloadApp();
  return (
    <footer className="black-bg footer-main">
      <div className="container">
        <div className="footer-logo footer-element-single">
          <img src="/img/aeldra-logo-gold.svg" alt="" />

          <p>
            Pronounced <span>El-dra</span> and sounds like{' '}
            <button type="button" className="button-unstyled" onClick={play}>
              <svg width="20" height="20" fill="none" viewBox="0 0 20 20">
                <path
                  fill="#5F5F5F"
                  stroke="#5F5F5F"
                  strokeLinejoin="round"
                  strokeWidth="1.333"
                  d="M10.505 3.672 5.931 7.628H1.6v5.092h4.264l4.64 4.013V3.672Z"
                />
                <path
                  stroke="#5F5F5F"
                  strokeLinecap="round"
                  strokeWidth="1.333"
                  d="M12.8 7.36a5.2 5.2 0 0 1 0 5.707m1.894-7.6a8 8 0 0 1 0 9.493m1.733-11.227a10.346 10.346 0 0 1 0 12.96"
                />
              </svg>
            </button>
            <audio id="audio" src="/video/aeldra-audio.mp3" />
          </p>
        </div>
        <div className="visible-mobile">
          <div className="footer-nav flex footer-element-single ">
            {footerLinks.map(({ linkText, url, id }) => (
              <Link key={id} to={url}>
                {linkText}
              </Link>
            ))}
          </div>
        </div>
        <div className="row">
          <div className="col col-3 footer-mobile-flex">
            <div className="footer-col-single">
              <div className="footer-button-area footer-element-single">
                <h3 className="footer-head">Start using Aeldra now</h3>

                <a
                  href="https://apps.apple.com/in/app/aeldra-global-bank/id1546022340"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-assistance-single button-alt button-gold">
                  <svg width="14" height="16" fill="none" viewBox="0 0 14 16">
                    <path
                      fill="#1F1F1F"
                      d="M11.66 15.356c-.901.842-1.895.71-2.841.313-1.007-.405-1.928-.43-2.99 0-1.325.551-2.027.391-2.825-.313-4.502-4.468-3.837-11.273 1.28-11.53 1.24.065 2.11.662 2.84.712 1.087-.213 2.126-.823 3.29-.743 1.396.109 2.441.64 3.14 1.596-2.875 1.666-2.194 5.316.446 6.341-.528 1.337-1.206 2.658-2.342 3.635l.001-.011ZM7.023 3.779C6.89 1.793 8.56.16 10.48 0c.264 2.29-2.16 4.003-3.456 3.779Z"
                    />
                  </svg>
                  Download for iPhone
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.i2c.mcpcc.aeldra.global"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-assistance-single button-alt button-gold">
                  <svg width="14" height="16" fill="none" viewBox="0 0 14 16">
                    <path
                      fill="#1F1F1F"
                      d="M3.067.933 10.8 5.4 8.6 7.6 1.667.733C1.8.733 2 .667 2.133.667c.334 0 .667.066.934.266ZM.4 13.6V2.4c0-.533.267-1 .667-1.333l7 7L1.133 15A1.74 1.74 0 0 1 .4 13.6Zm2.667 1.533c-.4.2-.867.267-1.267.2L8.6 8.6l2.133 2.133-7.666 4.4Zm9.666-5.6-1.4.8L9 8.067l2.333-2.334 1.334.8c.533.334.866.867.866 1.534.067.533-.266 1.133-.8 1.466Z"
                    />
                  </svg>
                  Download for Android
                </a>
              </div>

              <div className="footer-social footer-element-single">
                <h3 className="footer-head">Connect with us</h3>

                <div className="flex">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/AeldraGlobal/">
                    <svg width="34" height="34" fill="none" viewBox="0 0 34 34">
                      <path
                        fill="#BEB281"
                        d="M34 17c0-9.39-7.61-17-17-17S0 7.61 0 17s7.61 17 17 17c.1 0 .2 0 .299-.007V20.765h-3.652V16.51h3.652v-3.135c0-3.632 2.218-5.611 5.458-5.611 1.554 0 2.89.113 3.274.166v3.798H23.8c-1.76 0-2.105.837-2.105 2.066v2.709h4.217l-.552 4.257h-3.665v12.584C28.8 31.303 34 24.763 34 17Z"
                      />
                    </svg>
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/company/aeldra">
                    <svg width="34" height="34" fill="none" viewBox="0 0 34 34">
                      <path
                        fill="#BEB281"
                        d="M17 0C7.613 0 0 7.613 0 17s7.613 17 17 17 17-7.613 17-17S26.387 0 17 0Zm-4.94 25.7H7.92V13.242h4.14V25.7ZM9.99 11.541h-.027c-1.39 0-2.288-.956-2.288-2.151 0-1.223.926-2.153 2.343-2.153 1.416 0 2.287.93 2.314 2.153 0 1.195-.898 2.151-2.342 2.151ZM26.989 25.7h-4.14v-6.664c0-1.674-.6-2.816-2.097-2.816-1.144 0-1.825.77-2.124 1.514-.11.266-.137.638-.137 1.01V25.7h-4.14s.054-11.287 0-12.456h4.14v1.764c.55-.849 1.535-2.056 3.732-2.056 2.723 0 4.766 1.78 4.766 5.606v7.142Z"
                      />
                    </svg>
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://instagram.com/aeldra.globalbank?utm_medium=copy_link">
                    <svg width="34" height="34" fill="none" viewBox="0 0 34 34">
                      <path
                        fill="#BEB281"
                        d="M20.254 17a3.254 3.254 0 1 1-6.508 0 3.254 3.254 0 0 1 6.508 0Z"
                      />
                      <path
                        fill="#BEB281"
                        d="M24.609 11.243a3.023 3.023 0 0 0-.73-1.123 3.022 3.022 0 0 0-1.123-.73c-.344-.134-.86-.293-1.812-.336-1.03-.047-1.338-.057-3.945-.057-2.607 0-2.915.01-3.945.057-.951.043-1.468.202-1.812.336a3.02 3.02 0 0 0-1.122.73 3.026 3.026 0 0 0-.73 1.123c-.135.344-.294.86-.337 1.812-.047 1.03-.057 1.338-.057 3.945 0 2.607.01 2.915.057 3.945.043.951.202 1.468.336 1.812.157.424.406.808.73 1.123.315.324.699.573 1.123.73.344.134.86.293 1.812.336 1.03.047 1.338.057 3.945.057 2.607 0 2.916-.01 3.945-.057.951-.043 1.468-.202 1.812-.336a3.233 3.233 0 0 0 1.853-1.853c.133-.344.292-.86.336-1.812.047-1.03.057-1.338.057-3.945 0-2.607-.01-2.915-.057-3.945-.043-.951-.202-1.468-.336-1.812Zm-7.61 10.77a5.013 5.013 0 1 1 0-10.026 5.013 5.013 0 0 1 0 10.025Zm5.21-9.053a1.171 1.171 0 1 1 .001-2.343 1.171 1.171 0 0 1 0 2.343Z"
                      />
                      <path
                        fill="#BEB281"
                        d="M17 0C7.613 0 0 7.613 0 17s7.613 17 17 17 17-7.613 17-17S26.387 0 17 0Zm9.703 21.025c-.047 1.039-.213 1.748-.454 2.37a4.99 4.99 0 0 1-2.855 2.854c-.62.241-1.33.406-2.37.454-1.04.047-1.373.059-4.024.059s-2.983-.012-4.025-.06c-1.038-.047-1.748-.212-2.369-.453a4.782 4.782 0 0 1-1.73-1.126 4.785 4.785 0 0 1-1.125-1.729c-.241-.62-.406-1.33-.454-2.37-.048-1.04-.059-1.373-.059-4.024s.011-2.984.06-4.025c.046-1.039.211-1.748.453-2.37a4.782 4.782 0 0 1 1.126-1.728 4.782 4.782 0 0 1 1.729-1.126c.62-.241 1.33-.406 2.37-.454 1.04-.047 1.373-.059 4.024-.059s2.984.012 4.025.06c1.039.047 1.748.212 2.37.453.65.245 1.24.63 1.728 1.126.497.488.881 1.077 1.126 1.729.242.62.407 1.33.454 2.37.048 1.04.059 1.373.059 4.024s-.011 2.984-.06 4.025Z"
                      />
                    </svg>
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://youtu.be/oDY6sYXhxUA">
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
                      <path
                        d="M34 17c0-9.389-7.611-17-17-17S0 7.611 0 17s7.611 17 17 17 17-7.611 17-17Z"
                        fill="#BEB281"
                      />
                      <path
                        d="M16.994 8.979s-7.165 0-8.963.467a2.962 2.962 0 0 0-2.025 2.039c-.467 1.798-.467 5.522-.467 5.522s0 3.738.467 5.508a2.917 2.917 0 0 0 2.025 2.025c1.813.481 8.963.481 8.963.481s7.18 0 8.977-.467a2.849 2.849 0 0 0 2.01-2.025c.482-1.784.482-5.508.482-5.508s.014-3.738-.481-5.536a2.83 2.83 0 0 0-2.01-2.01c-1.799-.496-8.978-.496-8.978-.496Zm-2.28 4.587 5.962 3.441-5.962 3.427v-6.868Z"
                        fill="#1F1F1F"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            {/* 
            <div className="footer-col-single visible-mobile">
              <div className="footer-nav flex">
                <a href="#!">Products</a>
                <a href="#!">About us</a>
                <a href="#!">Add Funds</a>
                <a href="#!">FAQs</a>
                <a href="#!">Blogs</a>
                <a href="#!">Download app</a>
              </div>
            </div> */}

            <div className="footer-element-single">
              <h3 className="footer-head">Address</h3>
              <p>1117 S. California Ave, Palo Alto, CA 94304</p>
            </div>
          </div>

          <div className="col col-9">
            <div className="footer-nav flex footer-element-single hidden-mobile">
              {footerLinks.map(({ linkText, url, id }) => (
                <Link key={id} to={url}>
                  {linkText}
                </Link>
              ))}
            </div>

            <div className="footer-button-area footer-element-single ">
              <h3 className="footer-head">For 24x7 assistance</h3>
              <div className="footer-assistance-buttons flex-wrap">
                <a
                  href="tel:+918069559800"
                  className="footer-assistance-single button-alt">
                  India Contact number
                </a>
                <a
                  href="tel:+1 844 333 3101"
                  className="footer-assistance-single button-alt">
                  U.S. Toll Free Number
                </a>
                <a
                  href="mailto:concierge@aeldra.com"
                  className="footer-assistance-single button-alt">
                  Send us an email
                </a>
                <button
                  type="button"
                  onClick={popupAppClick}
                  className="footer-assistance-single button-alt">
                  Get Assistance in Account Opening
                </button>
              </div>
            </div>

            <div className="disclaimer-text">
              <p>© 2022 AELDRA. ALL RIGHTS RESERVED.</p>

              <p>
                *Banking services provided by Blue Ridge Bank, National
                Association, Member FDIC. The FDIC - short for Federal Deposit
                Insurance Corporation - is an independent agency of the United
                States government that protects against the loss of insured
                deposits if an FDIC insured bank fails. It is backed by the full
                faith and credit of the United States government. Since the FDIC
                began in 1934, no depositor has ever lost a penny of FDIC
                insured funds. FDIC insurance covers funds in deposit accounts
                up to $250,000 per depositor.
              </p>

              <p>
                ^The Aeldra Mastercard^ Debit Card is issued by Blue Ridge Bank,
                N.A. pursuant to a license from Mastercard^ Inc. and may be used
                everywhere Mastercard^ debit cards are accepted. Indian
                residents will be restricted from debit card usage until the
                customer is physically in the U.S. Customers will have to follow
                applicable Aeldra procedures which will include providing
                documentation that establishes physical presence in the U.S. and
                by contacting Aeldra Concierge to remove the usage restriction.
              </p>

              <p>
                ATM: Out-of-Network cash withdrawal fees apply. Third-party and
                cash deposit fees may apply.
              </p>

              <p>
                APY is variable and accurate as of 7/1/2022. Rates may be
                changed from time to time without notice
              </p>

              <p>**Images are illustrative only </p>
            </div>
          </div>
        </div>

        <div className="footer-terms">
          <div className="row">
            <div className="col col-8">
              <div className="footer-terms-link flex">
                <Link to="/product-agreement">Product Agreement</Link>
                <Link to="/terms-of-use">Terms of Use</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </div>
            </div>
            <div className="col col-4">
              <div className="made-with-love">
                <p className="gold">❤️ Born in the heart of Silicon Valley</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
