import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import './Button.scss';

/**
 * Primary UI component for user interaction
 */
export function Button({
  type,
  secondary,
  whiteButton,
  goldButton,
  label,
  href,
  children,
  onClick,
  disabled,
  target,
  lessRound,
  iconName,
  fullWidth,
  fullWidthMobile,
  ...props
}) {
  return href ? (
    <a
      href={href}
      onClick={onClick}
      target={target}
      className={[
        'button',
        secondary ? 'button-secondary' : '',
        whiteButton ? 'button-white' : '',
        goldButton ? 'button-gold' : '',
        lessRound ? 'button-lessround' : '',
        fullWidth ? 'button-full-width' : '',
        fullWidthMobile ? 'button-full-width-mobile' : '',
        { ...props }.className,
      ].join(' ')}>
      {iconName ? <Icon name={iconName} /> : ''}

      <span className="button-skew-block">
        <span className="button-skew-text" data-content={children}>
          {children}
        </span>
      </span>
    </a>
  ) : (
    <button
      /* eslint-disable-next-line react/button-has-type */
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={[
        'button',
        secondary ? 'button-secondary' : '',
        whiteButton ? 'button-white' : '',
        goldButton ? 'button-gold' : '',
        lessRound ? 'button-lessround' : '',
        fullWidth ? 'button-full-width' : '',
        fullWidthMobile ? 'button-full-width-mobile' : '',
        { ...props }.className,
      ].join(' ')}>
      {iconName ? <Icon name={iconName} /> : ''}
      <span className="button-skew-block">
        <span className="button-skew-text" data-content={children}>
          {children}
        </span>
      </span>
    </button>
  );
}

Button.propTypes = {
  type: PropTypes.string,
  /**
   * Is this the principal call to action on the page?
   */
  secondary: PropTypes.bool,
  whiteButton: PropTypes.bool,
  goldButton: PropTypes.bool,

  /**
   * Button contents
   */
  label: PropTypes.string,

  // href

  href: PropTypes.string,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,

  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  disabled: PropTypes.bool,
  lessRound: PropTypes.bool,
  fullWidth: PropTypes.bool,
  fullWidthMobile: PropTypes.bool,
  target: PropTypes.string,
  iconName: PropTypes.string,
};

Button.defaultProps = {
  type: 'button',
  secondary: false,
  whiteButton: false,
  goldButton: false,
  onClick: undefined,
  disabled: false,
  target: null,
  iconName: null,
  lessRound: false,
  fullWidth: false,
  fullWidthMobile: false,
};

export default Button;
