/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import { useDownloadApp } from './contactContext';

function ContactPopup() {
  const { popupApp, popupAppClick } = useDownloadApp();

  // const [inputFields, setInputFields] = useState({
  //   firstName: '',
  //   lastName: '',
  //   emailAddress: '',
  //   phoneNumber: '',
  //   newsLetter: false,
  // });

  // const inputChange = (e) => {
  //   setInputFields((state) => ({ ...state, [e.target.name]: e.target.value }));
  // };

  // const checkBoxChange = (e) => {
  //   setInputFields((state) => ({
  //     ...state,
  //     [e.target.name]: e.target.checked,
  //   }));
  // };

  const submitHandler = (e) => {
    e.preventDefault();
  };

  // const contactPopupTrigger = useCallback(() => {
  //   setcontactPopup((state) => !state);
  // }, []);

  // useEffect(() => {
  //   document.addEventListener('keydown', function (event) {
  //     if (event.key === 'Escape') {
  //       setcontactPopup(false);
  //     }
  //   });
  // }, []);

  return (
    <div className={`popup-block-main ${popupApp ? 'show' : ''}`}>
      <div className="popup-block black-bg">
        <button onClick={popupAppClick} className="close-popup" type="button">
          <svg width="20" height="20" fill="none" viewBox="0 0 20 20">
            <g
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.667"
              opacity=".52">
              <path d="M15 5 5 15M5 5l10 10" />
            </g>
          </svg>
        </button>

        <div className="popup-content">
          <h2 className="text-center">
            Get assistance in opening a U.S. bank account* now
          </h2>

          <form onSubmit={submitHandler}>
            <div className="get-in-touch-form">
              <div id="mc_embed_signup">
                <form
                  action="https://aeldra.us10.list-manage.com/subscribe/post?u=abae04faae6b84ac8c59e6ffb&amp;id=41c0a901ff"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  className="validate">
                  <div className="row">
                    <div className="col col-6">
                      <div className="form-group">
                        <div className="mc-field-group">
                          <label htmlFor="mce-FNAME">
                            First Name <span className="asterisk">*</span>
                          </label>
                          <input
                            type="text"
                            name="FNAME"
                            className="required border-bottom-input"
                            id="mce-FNAME"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col col-6">
                      <div className="form-group">
                        <div className="mc-field-group">
                          <label htmlFor="mce-LNAME">
                            Last Name <span className="asterisk">*</span>
                          </label>
                          <input
                            type="text"
                            name="LNAME"
                            className="required border-bottom-input"
                            id="mce-LNAME"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="form-group">
                        <div className="mc-field-group">
                          <label htmlFor="mce-EMAIL">
                            Email Address <span className="asterisk">*</span>
                          </label>
                          <input
                            type="email"
                            name="EMAIL"
                            className="required email border-bottom-input"
                            id="mce-EMAIL"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col col-12">
                      <div className="form-group">
                        <div className="mc-field-group">
                          <label htmlFor="mce-PHONE">
                            Phone Number <span className="asterisk">*</span>
                          </label>
                          <input
                            type="text"
                            name="PHONE"
                            className="required border-bottom-input"
                            id="mce-PHONE"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="col col-12">
                      <div className="flex form-group">
                        <div className="custom-checkbox">
                          <input
                            type="checkbox"
                            name="newsLetter"
                            id="newsLetter-check"
                            onChange={checkBoxChange}
                            checked={inputFields.newsLetter}
                          />
                          <label htmlFor="newsLetter-check">
                            I'd like to receive product updates and marketing
                            emails from Aeldra
                          </label>
                        </div>
                      </div>
                    </div> */}

                    <div className="col col-12">
                      <div id="mce-responses" className="clear">
                        <div
                          className="response"
                          id="mce-error-response"
                          style={{ display: 'none' }}></div>
                        <div
                          className="response"
                          id="mce-success-response"
                          style={{ display: 'none' }}></div>
                      </div>
                      <div
                        style={{ position: 'absolute', left: -5000 }}
                        aria-hidden="true">
                        <input
                          type="text"
                          name="b_abae04faae6b84ac8c59e6ffb_41c0a901ff"
                          tabIndex="-1"
                          value=""
                        />
                      </div>
                      <input
                        type="submit"
                        value="Get in touch"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        className="button button-gold button-full-width"
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  </div>{' '}
                </form>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactPopup;
