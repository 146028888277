import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'normalize.css';

import { Helmet } from 'react-helmet';
import Header from './Header/Header';
import '../styles/master.scss';
import Footer from './Footer/Footer';
import Loader from './Loader/Loader';
import { DownloadAppProvider } from './ContactPopup/contactContext';
function Layout({ children, lightHeader, fixedHeader }) {
  const wrapWords = useCallback(() => {
    Array.from(document.querySelectorAll('.wrap-words')).forEach((el) => {
      el.innerHTML = el.textContent.replace(
        /\w+/g,
        '<span><span>$&</span></span>',
      );
    });
  }, []);

  useEffect(() => {
    wrapWords();
  }, []);

  return (
    <DownloadAppProvider>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-170081712-1"
        />

        <script>
          {`
          window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date()); 
            gtag('config', 'UA-170081712-1');
          `}
        </script>
        <script id="ruttl-site-embed-script" src="https://staging.ruttl.com/plugin.js?id=2UGEycjEDUJ3UMcCfnph&e=0" defer async></script>
      </Helmet>
      <Loader />

      <Header lightHead={lightHeader} fixHead={fixedHeader} />

      {/* {lightHeader ? (
        <Header lightHead />
      ) : fixedHeader ? (
        <Header fixHead />
      ) : (
        <Header />
      )} */}

      <main>{children}</main>
      <Footer />
    </DownloadAppProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  lightHeader: PropTypes.bool,
  fixedHeader: PropTypes.bool,
};

Layout.defaultProps = {
  lightHeader: false,
  fixedHeader: false,
};

export default Layout;
