import React, { useEffect, useCallback, useState } from 'react';
import { loadingText } from '../../utils/contentProvider';

import './Loader.scss';
const windowGlobal = typeof window !== 'undefined' && window;

function Loader() {
  const [loading, setloading] = useState(false);
  const [showLoader, setshowLoader] = useState(false);

  const [loadingTextAnim, setloadingTextAnim] = useState(() => {
    if (windowGlobal && windowGlobal.localStorage) {
      return windowGlobal.localStorage.getItem('siteLoaded') === 'true' ? 7 : 0;
    }

    return 0;
  });

  const showLoadercallback = useCallback(() => {
    setshowLoader(true);
  }, []);

  const updateloadingTextAnim = useCallback(() => {
    setloadingTextAnim((state) => (state === 7 ? state : state + 1));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => updateloadingTextAnim(), 200);

    // if (windowGlobal.localStorage.getItem('siteLoaded') === 'true') {
    //   clearInterval(interval);
    // }

    return () => clearInterval(interval);
  }, [updateloadingTextAnim]);

  useEffect(() => {
    showLoadercallback();
    setloading(loadingTextAnim === 7);
  }, [loadingTextAnim]);

  useEffect(() => {
    windowGlobal.localStorage.setItem(
      'siteLoaded',
      loadingTextAnim === 7 ? 'true' : 'false',
    );
  }, [loadingTextAnim]);
  return (
    <div
      className={`loader ${loading ? 'loaded' : ''}`}
      style={{
        backgroundColor: '#000',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}>
      <div
        style={{
          opacity: showLoader ? 1 : 0,
          width: '100%',
          textAlign: 'center',
        }}>
        <img src="/img/aeldra-logo-white.png" alt="" />
        {loadingText.map(({ text, id }) => (
          <div key={id} className={id === loadingTextAnim ? 'active' : ''}>
            <span className="loader-words">{text}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Loader;
