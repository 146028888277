import React from 'react';
import PropTypes from 'prop-types';
import { getIcons } from '../utils/contentProvider';

function Icon({
  name,
  width,
  height,
  fill,
  rotate,
  title,
  verticalAlign,
  className,
  style,
}) {
  let current = [...getIcons()].find((i) => i.name === name);

  // Set the icon to 'warning' if not found.
  current =
    current === undefined
      ? [...getIcons()].find((i) => i.name === 'warning')
      : current;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || current.width}
      height={height || current.height}
      className={className}
      viewBox={current.viewBox}
      fill={fill || current.fill}
      style={{
        ...(rotate ? { transform: rotate ? `rotate(${rotate})` : '' } : {}),
        verticalAlign,
        ...style,
      }}
      title={title}>
      {current.svg}
    </svg>
  );
}

Icon.defaultProps = {
  width: null,
  height: null,
  fill: null,
  rotate: null,
  title: null,
  verticalAlign: null,
  style: {},
  className: null,
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  rotate: PropTypes.string,
  title: PropTypes.string,
  verticalAlign: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  className: PropTypes.string,
};

export default Icon;
